import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  Button,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  useTheme,
  useMediaQuery,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Tab,
  Tabs,
  Alert,
  CircularProgress,
  alpha,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChecklistIcon from '@mui/icons-material/Checklist';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import PieChartIcon from '@mui/icons-material/PieChart';
import DescriptionIcon from '@mui/icons-material/Description';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StatusChipProps, Project } from '../types';
import { projectAPI } from '../apis';
import { isAuthenticated } from '../utils/auth';
import { grey } from '@mui/material/colors';

const MetricCard = styled(Card)(({ theme }: { theme: Theme }) => ({
  height: '140px',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '16px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  background: theme.palette.background.paper,
}));

const MetricValue = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: '60px',
  fontWeight: 700,
  lineHeight: 1,
  marginBottom: '12px',
  color: theme.palette.text.primary,
}));

const StyledTable = styled(Table)({
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #f0f0f0',
    padding: '16px',
  },
  '& .MuiTableCell-head': {
    fontWeight: 600,
    backgroundColor: '#f8f9fa',
  },
});

const StatusChip = styled(Chip)<StatusChipProps>(({ status, theme }) => {
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'in_progress':
        return {
          bg: '#FFF6DE',
          color: '#E5A206',
          border: '#F5D679',
        };
      case 'completed':
        return {
          bg: '#E8F5E9',
          color: '#2E7D32',
          border: '#A5D6A7',
        };
      case 'pending':
        return {
          bg: '#E3F2FD',
          color: '#1976D2',
          border: '#90CAF9',
        };
      default:
        return {
          bg: theme.palette.grey[100],
          color: theme.palette.grey[700],
          border: theme.palette.grey[300],
        };
    }
  };

  const colors = getStatusColor(status);
  return {
    backgroundColor: colors.bg,
    color: colors.color,
    border: `1px solid ${colors.border}`,
    fontWeight: 600,
    borderRadius: '20px',
    padding: '4px 12px',
  };
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    transform: 'scale(1.001)',
  },
}));

const FilterTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  minWidth: 'auto',
  padding: '12px 16px',
  borderRadius: '8px',
  margin: '0 4px',
  color: theme.palette.text.secondary,
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [filter, setFilter] = useState('all');
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [metrics, setMetrics] = useState({
    activeProjects: 0,
    pendingReviews: 0,
    projectDocuments: 0,
  });

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        setError(null);

        if (!isAuthenticated()) {
          navigate('/login', { state: { from: '/dashboard' } });
          return;
        }

        const response = await projectAPI.getProjects();
        
        if (response.status === 'success' && response.data) {
          setProjects(response.data);
          
          // Calculate metrics from project data
          const activeCount = response.data.filter(p => p.status === 'in_progress').length;
          const pendingCount = response.data.filter(p => p.status === 'pending').length;
          const totalProjects = response.data.length;
          
          setMetrics({
            activeProjects: activeCount,
            pendingReviews: pendingCount,
            projectDocuments: totalProjects,
          });
        } else {
          throw new Error(response.message || 'Failed to fetch projects');
        }
      } catch (error: any) {
        console.error('Error fetching projects:', error);
        if (error.status === 401) {
          navigate('/login', { state: { from: '/dashboard' } });
        } else {
          setError('Failed to load projects. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [navigate]);

  const metricCards = [
    { 
      title: 'Active Project', 
      value: metrics.projectDocuments.toString(), 
      icon: <PieChartIcon sx={{ fontSize: 40, color: '#4169E1' }} /> 
    },
    { 
      title: 'Pending Approvals', 
      value: metrics.pendingReviews.toString(), 
      icon: <ChecklistIcon sx={{ fontSize: 40, color: '#7B7D8C' }} /> 
    },
    { 
      title: 'Queued Documents', 
      value: metrics.activeProjects.toString(), 
      icon: <DescriptionIcon sx={{ fontSize: 40, color: '#2A2C40' }} /> 
    },
  ];

  const filteredProjects = filter === 'all' 
    ? projects 
    : projects.filter(project => {
        if (filter === 'in_progress') {
          return project.status === 'in_progress' || project.status === 'pending';
        }
        return project.status.toLowerCase().replace(' ', '_') === filter;
      });

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ 
      pt: '80px',
      bgcolor: '#F8F9FA', 
      minHeight: '100vh',
      pb: 4,
    }}>
      <Container maxWidth="lg">
        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              mb: 3,
              borderRadius: 2,
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            }}
          >
            {error}
          </Alert>
        )}

        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: { 
            xs: '1fr', 
            sm: 'repeat(2, 1fr)', 
            md: 'repeat(3, 1fr)' 
          }, 
          gap: 3, 
          mb: 4 
        }}>
          {metricCards.map((metric) => (
            <MetricCard key={metric.title}>
              <Typography color="text.secondary" sx={{ fontSize: '1rem', fontWeight: 500 }}>
                {metric.title}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <MetricValue>
                  {metric.value}
                </MetricValue>
                <Box sx={{
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  {metric.icon}
                </Box>
              </Box>
            </MetricCard>
          ))}
        </Box>

        <Box sx={{ mb: 4 }}>
          <Paper 
            sx={{ 
              borderRadius: '16px',
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              overflow: 'hidden'
            }}
          >
            <Box sx={{ 
              p: 3,
              borderBottom: '1px solid #f0f0f0',
            }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 3,
              }}>
                <Typography variant="h5" fontWeight="600">Projects</Typography>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/new-project')}
                  sx={{
                    borderRadius: '30px',
                    textTransform: 'none',
                    px: 3,
                    py: 1.2,
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                    bgcolor: '#4169E1',
                    '&:hover': {
                      bgcolor: '#3457cd',
                    },
                    transition: 'all 0.2s ease',
                    fontWeight: 600,
                  }}
                >
                  Create New Project
                </Button>
              </Box>

              <Box sx={{ bgcolor: '#FFFFFF', borderRadius: '30px', display: 'inline-flex', p: '4px' }}>
                <Button 
                  variant={filter === 'all' ? 'contained' : 'text'} 
                  onClick={() => setFilter('all')}
                  sx={{ 
                    borderRadius: '30px', 
                    textTransform: 'none', 
                    px: 3,
                    py: 1,
                    mr: 1,
                    bgcolor: filter === 'all' ? '#2A2C40' : '#F2F2F2',
                    color: filter === 'all' ? '#fff' : '#2A2C40',
                    '&:hover': {
                      bgcolor: filter === 'all' ? '#2A2C40' : '#E5E5E5',
                    },
                    fontWeight: 600,
                  }}
                >
                  All
                </Button>
                <Button 
                  variant={filter === 'in_progress' ? 'contained' : 'text'} 
                  onClick={() => setFilter('in_progress')}
                  sx={{ 
                    borderRadius: '30px', 
                    textTransform: 'none', 
                    px: 3,
                    py: 1,
                    mr: 1,
                    bgcolor: filter === 'in_progress' ? '#2A2C40' : '#F2F2F2',
                    color: filter === 'in_progress' ? '#fff' : '#2A2C40',
                    '&:hover': {
                      bgcolor: filter === 'in_progress' ? '#2A2C40' : '#E5E5E5',
                    },
                    fontWeight: 600,
                  }}
                >
                  Active & Pending
                </Button>
                <Button 
                  variant={filter === 'completed' ? 'contained' : 'text'} 
                  onClick={() => setFilter('completed')}
                  sx={{ 
                    borderRadius: '30px', 
                    textTransform: 'none', 
                    px: 3,
                    py: 1,
                    mr: 1,
                    bgcolor: filter === 'completed' ? '#2A2C40' : '#F2F2F2',
                    color: filter === 'completed' ? '#fff' : '#2A2C40',
                    '&:hover': {
                      bgcolor: filter === 'completed' ? '#2A2C40' : '#E5E5E5',
                    },
                    fontWeight: 600,
                  }}
                >
                  Completed
                </Button>
                <Button 
                  variant={filter === 'draft' ? 'contained' : 'text'} 
                  onClick={() => setFilter('draft')}
                  sx={{ 
                    borderRadius: '30px', 
                    textTransform: 'none', 
                    px: 3,
                    py: 1,
                    bgcolor: filter === 'draft' ? '#2A2C40' : '#F2F2F2',
                    color: filter === 'draft' ? '#fff' : '#2A2C40',
                    '&:hover': {
                      bgcolor: filter === 'draft' ? '#2A2C40' : '#E5E5E5',
                    },
                    fontWeight: 600,
                  }}
                >
                  Draft
                </Button>
              </Box>
            </Box>

            {filteredProjects.length === 0 ? (
              <Box 
                sx={{ 
                  p: 6, 
                  textAlign: 'center', 
                  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.paper, 1)} 100%)`,
                }}
              >
                <Typography variant="h6" color="text.secondary" gutterBottom sx={{ mb: 2 }}>
                  No projects found
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph>
                  {filter === 'all' 
                    ? 'Start by creating your first project'
                    : `No ${filter.replace('_', ' ')} projects found`}
                </Typography>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/new-project')}
                  sx={{
                    borderRadius: '30px',
                    textTransform: 'none',
                    px: 4,
                    py: 1.5,
                    fontSize: '1rem',
                    bgcolor: '#4169E1',
                  }}
                >
                  Create Project
                </Button>
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Function</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Last Updated</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProjects.map((project) => (
                    <StyledTableRow 
                      key={project.projectID}
                      onClick={() => navigate(`/project/${project.projectID}`)}
                    >
                      <TableCell>
                        <Typography fontWeight={600}>{project.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="text.secondary">{project.projectType}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="text.secondary">{project.address || 'N/A'}</Typography>
                      </TableCell>
                      <TableCell>
                        <StatusChip
                          label={project.status === 'in_progress' ? 'In Progress' : 
                                 project.status === 'completed' ? 'Complete' : 'Pending'}
                          status={project.status}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Typography color="text.secondary">
                          Today, 2:30 PM
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/project/${project.projectID}/edit`);
                          }}
                          sx={{
                            color: 'text.secondary',
                            '&:hover': {
                              color: 'primary.main',
                              bgcolor: alpha(theme.palette.primary.main, 0.1),
                            },
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default Dashboard; 