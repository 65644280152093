import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  MenuItem,
  IconButton,
  Alert,
  CircularProgress,
  useTheme,
  alpha,
  Divider,
  Stepper,
  Step,
  StepLabel,
  List,
  ListItem,
  ListItemText,
  Popper,
  ClickAwayListener,
} from '@mui/material';
import { ArrowBack as ArrowBackIcon, LocationOn as LocationIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Project, Jurisdiction } from '../types';
import { projectAPI, jurisdictionAPI } from '../apis';
import { styled } from '@mui/material/styles';

const PROJECT_TYPES = [
  'Commercial',
  'Residential',
  'Industrial',
  'Infrastructure',
  'Mixed-Use',
  'Other'
];

const steps = ['Basic Information', 'Location Details', 'Review & Submit'];

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    transition: 'all 0.2s ease',
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.paper, 0.95),
      '& fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
      },
    },
    '&.Mui-focused': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
      },
      backgroundColor: alpha(theme.palette.background.paper, 1),
    },
  },
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '16px',
  boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  background: theme.palette.background.paper,
}));

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    fontSize: '0.9rem',
    fontWeight: 500,
    '&.Mui-active': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    '&.Mui-completed': {
      color: theme.palette.success.main,
      fontWeight: 600,
    },
  },
  '& .MuiStepIcon-root': {
    width: 32,
    height: 32,
    '&.Mui-active': {
      color: theme.palette.primary.main,
    },
    '&.Mui-completed': {
      color: theme.palette.success.main,
    },
  },
}));

// Limit for OpenStreetMap API requests
const OSM_SEARCH_DELAY = 300; // milliseconds

const NewProject: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [jurisdictions, setJurisdictions] = useState<Jurisdiction[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<Partial<Project>>({
    name: '',
    description: '',
    address: '',
    parcelNumber: '',
    projectType: '',
    jurisdictionID: '',
    estimatedCost: undefined,
    status: 'pending',
  });
  
  // Address autocomplete state
  const [addressInput, setAddressInput] = useState(formData.address || '');
  const [addressSuggestions, setAddressSuggestions] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const addressInputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchJurisdictions = async () => {
      try {
        const response = await jurisdictionAPI.getJurisdictions();
        if (response.status === 'success' && response.data) {
          setJurisdictions(response.data);
        }
      } catch (error) {
        console.error('Error fetching jurisdictions:', error);
        setError('Failed to load jurisdictions. Please try again later.');
      }
    };

    fetchJurisdictions();
  }, []);
  
  // Handle address search - uses OpenStreetMap Nominatim API (free and open-source)
  // This implementation respects Nominatim usage policy by:
  // 1. Implementing a search delay to limit request frequency
  // 2. Using a proper User-Agent header
  // 3. Setting a reasonable result limit
  useEffect(() => {
    if (addressInput.trim().length < 3) {
      setAddressSuggestions([]);
      setShowSuggestions(false);
      return;
    }
    
    // Clear previous timeout
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    
    // Set new timeout to avoid making too many requests
    searchTimeoutRef.current = setTimeout(async () => {
      setIsSearching(true);
      try {
        // Using the free OpenStreetMap Nominatim API for geocoding
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(addressInput)}&limit=5`,
          {
            headers: {
              'Accept-Language': 'en-US,en',
              'User-Agent': 'Augence/1.0' // It's good practice to identify your app
            }
          }
        );
        
        if (response.ok) {
          const data = await response.json();
          setAddressSuggestions(data);
          setShowSuggestions(data.length > 0);
        } else {
          console.error('Failed to fetch address suggestions');
          setAddressSuggestions([]);
        }
      } catch (error) {
        console.error('Error fetching address suggestions:', error);
        setAddressSuggestions([]);
      } finally {
        setIsSearching(false);
      }
    }, OSM_SEARCH_DELAY);
    
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, [addressInput]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    if (name === 'address') {
      setAddressInput(value);
    }
    
    setFormData(prev => ({
      ...prev,
      [name]: name === 'estimatedCost' ? (value === '' ? undefined : Number(value)) : value
    }));
  };
  
  const handleAddressSuggestionSelect = (suggestion: any) => {
    // Format address from the suggestion
    const address = suggestion.display_name;
    
    setFormData(prev => ({
      ...prev,
      address
    }));
    
    setAddressInput(address);
    setShowSuggestions(false);
  };

  const isStepComplete = (step: number) => {
    switch (step) {
      case 0:
        return !!formData.name && !!formData.description && !!formData.projectType && !!formData.estimatedCost;
      case 1:
        return !!formData.address && !!formData.parcelNumber && !!formData.jurisdictionID;
      case 2:
        return true;
      default:
        return false;
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmit();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await projectAPI.createProject(formData);
      
      if (response.status === 'success' && response.data) {
        navigate('/dashboard');
      } else {
        throw new Error(response.message || 'Failed to create project');
      }
    } catch (error: any) {
      console.error('Error creating project:', error);
      if (error.status === 401) {
        navigate('/login', { state: { from: '/new-project' } });
      } else {
        setError(error.message || 'Failed to create project. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  required
                  label="Project Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  required
                  multiline
                  rows={4}
                  label="Project Description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Enter a detailed description of your project..."
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  select
                  fullWidth
                  required
                  label="Project Type"
                  name="projectType"
                  value={formData.projectType}
                  onChange={handleInputChange}
                >
                  {PROJECT_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </StyledTextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  required
                  type="number"
                  label="Estimated Cost"
                  name="estimatedCost"
                  value={formData.estimatedCost}
                  onChange={handleInputChange}
                  placeholder="Enter estimated cost"
                  InputProps={{
                    startAdornment: <Typography sx={{ mr: 1, color: 'text.secondary' }}>$</Typography>,
                  }}
                />
              </Grid>
            </Grid>
          </motion.div>
        );
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box ref={addressInputRef} sx={{ position: 'relative', width: '100%' }}>
                  <StyledTextField
                    fullWidth
                    required
                    label="Address"
                    name="address"
                    value={addressInput}
                    onChange={handleInputChange}
                    placeholder="Start typing to see suggestions..."
                    InputProps={{
                      startAdornment: (
                        <LocationIcon sx={{ 
                          color: 'text.secondary', 
                          mr: 1,
                          fontSize: '1.2rem' 
                        }} />
                      ),
                      endAdornment: isSearching ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null,
                    }}
                  />
                  <Popper
                    open={showSuggestions}
                    anchorEl={addressInputRef.current}
                    placement="bottom-start"
                    style={{ width: addressInputRef.current?.clientWidth, zIndex: 1000 }}
                  >
                    <ClickAwayListener onClickAway={() => setShowSuggestions(false)}>
                      <Paper 
                        elevation={3} 
                        sx={{ 
                          mt: 1, 
                          maxHeight: '300px', 
                          overflow: 'auto',
                          borderRadius: '12px' 
                        }}
                      >
                        <List sx={{ p: 0 }}>
                          {addressSuggestions.length === 0 ? (
                            <ListItem>
                              <ListItemText primary="No results found" />
                            </ListItem>
                          ) : (
                            addressSuggestions.map((suggestion) => (
                              <ListItem 
                                key={suggestion.place_id} 
                                onClick={() => handleAddressSuggestionSelect(suggestion)}
                                sx={{ 
                                  cursor: 'pointer',
                                  '&:hover': {
                                    bgcolor: alpha(theme.palette.primary.main, 0.08),
                                  }
                                }}
                              >
                                <ListItemText 
                                  primary={suggestion.display_name}
                                  secondary={suggestion.type}
                                  primaryTypographyProps={{ 
                                    variant: 'body2',
                                    fontWeight: 500,
                                    sx: { mb: 0.5 } 
                                  }}
                                  secondaryTypographyProps={{ 
                                    variant: 'caption',
                                    color: 'text.secondary'
                                  }}
                                />
                              </ListItem>
                            ))
                          )}
                        </List>
                      </Paper>
                    </ClickAwayListener>
                  </Popper>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  required
                  label="Parcel Number"
                  name="parcelNumber"
                  value={formData.parcelNumber}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  select
                  fullWidth
                  required
                  label="Jurisdiction"
                  name="jurisdictionID"
                  value={formData.jurisdictionID}
                  onChange={handleInputChange}
                >
                  {jurisdictions.map((jurisdiction) => (
                    <MenuItem key={jurisdiction.jurisdictionID} value={jurisdiction.jurisdictionID}>
                      {jurisdiction.name} ({jurisdiction.city}, {jurisdiction.state})
                    </MenuItem>
                  ))}
                </StyledTextField>
              </Grid>
            </Grid>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Box>
              <Typography 
                variant="h6" 
                gutterBottom 
                sx={{ 
                  fontWeight: 600,
                  mb: 3,
                }}
              >
                Review Project Details
              </Typography>
              
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper 
                    sx={{ 
                      p: 3, 
                      borderRadius: '16px',
                      bgcolor: '#FFFFFF',
                      mb: 3,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="subtitle1" fontWeight={600} color="text.primary">Project Information</Typography>
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                          <Typography color="text.secondary" variant="body2">Project Name</Typography>
                          <Typography variant="body1" fontWeight={500}>{formData.name || 'Not specified'}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                          <Typography color="text.secondary" variant="body2">Project Type</Typography>
                          <Typography variant="body1" fontWeight={500}>{formData.projectType || 'Not specified'}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ mb: 2 }}>
                          <Typography color="text.secondary" variant="body2">Description</Typography>
                          <Typography variant="body1">{formData.description || 'Not specified'}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                
                <Grid item xs={12}>
                  <Paper 
                    sx={{ 
                      p: 3, 
                      borderRadius: '16px',
                      bgcolor: '#FFFFFF',
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="subtitle1" fontWeight={600} color="text.primary">Location Details</Typography>
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                          <Typography color="text.secondary" variant="body2">Address</Typography>
                          <Typography variant="body1" fontWeight={500}>{formData.address || 'Not specified'}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                          <Typography color="text.secondary" variant="body2">Parcel Number</Typography>
                          <Typography variant="body1" fontWeight={500}>{formData.parcelNumber || 'Not specified'}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                          <Typography color="text.secondary" variant="body2">Jurisdiction</Typography>
                          <Typography variant="body1" fontWeight={500}>
                            {jurisdictions.find(j => j.jurisdictionID === formData.jurisdictionID)?.name || 'Not specified'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                          <Typography color="text.secondary" variant="body2">Estimated Cost</Typography>
                          <Typography variant="body1" fontWeight={600} color="#4169E1">
                            ${Number(formData.estimatedCost).toLocaleString() || 'Not specified'}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        bgcolor: '#F8F9FA',
        minHeight: '100vh',
        pt: '80px',
        pb: 4,
      }}
    >
      <Container maxWidth="md">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                bgcolor: '#FFFFFF',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                '&:hover': {
                  bgcolor: '#F2F2F2',
                },
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography 
              variant="h5" 
              sx={{ 
                fontWeight: 600,
                color: '#2A2C40',
              }}
            >
              Create New Project
            </Typography>
          </Box>

          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 3,
                borderRadius: 2,
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              }}
            >
              {error}
            </Alert>
          )}

          <StyledPaper>
            <Stepper 
              activeStep={activeStep} 
              sx={{ 
                mb: 4,
                '& .MuiStepConnector-line': {
                  minHeight: 2,
                },
                '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
                  borderColor: '#4169E1',
                },
                '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
                  borderColor: theme.palette.success.main,
                },
              }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StyledStepLabel>{label}</StyledStepLabel>
                </Step>
              ))}
            </Stepper>

            <Box sx={{ mt: 4 }}>
              {renderStepContent(activeStep)}
            </Box>

            <Divider sx={{ my: 4 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                onClick={handleBack}
                disabled={activeStep === 0 || loading}
                sx={{
                  borderRadius: '30px',
                  textTransform: 'none',
                  px: 3,
                  py: 1,
                  bgcolor: '#F2F2F2',
                  color: '#2A2C40',
                  fontWeight: 600,
                  '&:hover': {
                    bgcolor: '#E5E5E5',
                  },
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!isStepComplete(activeStep) || loading}
                sx={{
                  borderRadius: '30px',
                  textTransform: 'none',
                  px: 3,
                  py: 1,
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  bgcolor: '#4169E1',
                  '&:hover': {
                    bgcolor: '#3457cd',
                  },
                  transition: 'all 0.2s ease',
                  fontWeight: 600,
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : activeStep === steps.length - 1 ? (
                  'Create Project'
                ) : (
                  'Next'
                )}
              </Button>
            </Box>
          </StyledPaper>
        </motion.div>
      </Container>
    </Box>
  );
};

export default NewProject; 