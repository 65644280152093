import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Link,
  Alert,
  CircularProgress,
  useTheme,
  IconButton,
  InputAdornment,
  Grid,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { authAPI } from '../apis/index';
import { styled } from '@mui/material/styles';
// Import the background image
import loginBg from '../assets/images/login-background.png';

// Logo styled component (assuming it's needed based on Figma)
const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(4),
}));

// Replicate Input field styling from Figma (similar to NewProject)
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary, // Label color from Figma
    fontSize: '14px',
    fontWeight: 400,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px', // Border radius from Figma
    backgroundColor: '#F9F9F9', // Input background from Figma
    '& fieldset': {
      borderColor: 'transparent', // Remove default border
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.light, // Border on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main, // Border on focus
      borderWidth: '1px',
    },
  },
  '& .MuiInputBase-input': {
    padding: '16px 24px', // Padding from Figma
    fontSize: '16px',
    fontWeight: 400,
  },
}));

const LoginPage: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleTogglePassword = () => {
    setShowPassword(prev => !prev);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.email || !formData.password) {
      setError('Please fill in all fields');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const response = await authAPI.login(formData);
      
      if (response.status === 'success') {
        navigate('/dashboard');
      } else {
        throw new Error(response.message || 'Invalid email or password');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Invalid email or password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    // Main container to center the card
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        bgcolor: '#F0F2F5', // A light grey background for the page
        p: { xs: 2, sm: 3, md: 4 },
      }}
    >
      {/* Centered Card */}
      <Paper
        elevation={8} // Slightly more pronounced shadow
        sx={{
          display: 'flex',
          borderRadius: '16px', 
          overflow: 'hidden',
          maxWidth: '960px', // Max width for the card
          width: '100%',
          boxShadow: '0 8px 40px rgba(0,0,0,0.12)'
        }}
      >
        <Grid container>
          {/* Left Side - Form */}
          <Grid item xs={12} sm={7} md={6}>
            <Box
              sx={{
                py: { xs: 4, sm: 6, md: 8 }, // Responsive padding
                px: { xs: 3, sm: 4, md: 5 }, // Responsive padding
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%', // Fill height of the grid item
                justifyContent: 'center' // Center form vertically
              }}
            >
              {/* Logo Placeholder */}
              <LogoContainer>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#4169E1' }}>
                  Augence
                </Typography>
              </LogoContainer>
              
              {/* Title & Subtitle */}
              <Typography component="h1" variant="h5" sx={{ fontWeight: 700, mb: 1, color: '#313850' }}>
                Sign In
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
                Welcome back! Please enter your details.
              </Typography>

              {/* Error Alert */}
              {error && (
                <Alert 
                  severity="error" 
                  sx={{ 
                    mb: 2, 
                    width: '100%',
                    borderRadius: '8px',
                  }}
                >
                  {error}
                </Alert>
              )}

              {/* Form */}
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1, width: '100%' }}
              >
                {/* Email Field */}
                <StyledTextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {/* Password Field */}
                <StyledTextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  value={formData.password}
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePassword}
                          edge="end"
                          sx={{ color: 'text.secondary' }}
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* Remember Me & Forgot Password */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2 }}>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" sx={{ p: 0.5, mr: 0.5 }} />}
                    label={<Typography variant="body2" sx={{ fontSize: '14px' }}>Remember me</Typography>}
                  />
                  <Link href="#" variant="body2" sx={{ textDecoration: 'none', fontWeight: 500, color: 'primary.main' }}>
                    Forgot password?
                  </Link>
                </Box>
                {/* Sign In Button */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading}
                  sx={{
                    mt: 2,
                    mb: 2,
                    py: 1.5,
                    borderRadius: '200px',
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 600,
                    bgcolor: '#4169E1',
                    boxShadow: '0 4px 12px rgba(65, 105, 225, 0.3)',
                    '&:hover': {
                      bgcolor: '#3457CD',
                      boxShadow: '0 6px 16px rgba(65, 105, 225, 0.4)',
                    },
                  }}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
                </Button>
                {/* Sign Up Link */}
                <Grid container justifyContent="center" sx={{ mt: 2 }}>
                  <Grid item>
                    <Link 
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate('/register');
                      }}
                      variant="body2"
                      sx={{ textDecoration: 'none', color: 'text.secondary' }}
                    >
                      {"Don't have an account? "}
                      <Typography component="span" sx={{ fontWeight: 600, color: 'primary.main' }}>
                        Sign Up
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>

          {/* Right Side - Image */}
          <Grid
            item
            xs={false} 
            sm={5}
            md={6}
            sx={{
              // background: 'linear-gradient(135deg, #6e8efb 0%, #a777e3 100%)', 
              backgroundImage: `url(${loginBg})`, // Use the imported image
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderTopRightRadius: '16px', 
              borderBottomRightRadius: '16px',
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            }}
          />
        </Grid>
      </Paper>
    </Box>
  );
};

export default LoginPage; 