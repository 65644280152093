import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Button,
  IconButton,
  CircularProgress,
  Alert,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  alpha,
  styled,
} from '@mui/material';
import {
  Add as AddIcon,
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Project } from '../types';
import { projectAPI } from '../apis';

type ProjectStatus = 'all' | 'pending' | 'in_progress' | 'completed' | 'draft';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    transform: 'scale(1.001)',
  },
}));

interface StatusChipProps {
  status: string;
  label?: string;
  size?: 'small' | 'medium';
}

const StatusChip = styled('div')<StatusChipProps>(({ status, theme }) => {
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'in_progress':
        return {
          bg: '#FFF6DE',
          color: '#E5A206',
          border: '#F5D679',
        };
      case 'completed':
        return {
          bg: '#E8F5E9',
          color: '#2E7D32',
          border: '#A5D6A7',
        };
      case 'pending':
        return {
          bg: '#E3F2FD',
          color: '#1976D2',
          border: '#90CAF9',
        };
      default:
        return {
          bg: theme.palette.grey[100],
          color: theme.palette.grey[700],
          border: theme.palette.grey[300],
        };
    }
  };

  const colors = getStatusColor(status);
  return {
    backgroundColor: colors.bg,
    color: colors.color,
    border: `1px solid ${colors.border}`,
    fontWeight: 600,
    borderRadius: '20px',
    padding: '4px 12px',
    display: 'inline-block',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  };
});

const Projects: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState<ProjectStatus>('all');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await projectAPI.getProjects();
        if (response.status === 'success' && response.data) {
          setProjects(response.data);
        } else {
          throw new Error(response.message || 'Failed to fetch projects');
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
        setError('Failed to load projects. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  // Filter projects based on selected status and search query
  const filteredProjects = projects.filter(project => {
    // Filter by status
    if (filter !== 'all') {
      if (filter === 'in_progress') {
        if (project.status !== 'in_progress' && project.status !== 'pending') return false;
      } else if (project.status !== filter) {
        return false;
      }
    }
    
    // Filter by search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      return project.name.toLowerCase().includes(query) ||
        project.description.toLowerCase().includes(query) ||
        project.address.toLowerCase().includes(query);
    }
    return true;
  });

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ 
      pt: '80px',
      minHeight: '100vh',
      bgcolor: '#F8F9FA',
      pb: 4
    }}>
      <Container maxWidth="lg">
        <Paper 
          sx={{ 
            borderRadius: '16px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
            overflow: 'hidden',
            mb: 4
          }}
        >
          <Box sx={{ 
            p: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <Typography variant="h5" fontWeight="600">Projects</Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate('/new-project')}
                sx={{
                borderRadius: '30px',
                textTransform: 'none',
                px: 3,
                py: 1.2,
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                bgcolor: '#4169E1',
                  '&:hover': {
                  bgcolor: '#3457cd',
                  },
                transition: 'all 0.2s ease',
                fontWeight: 600,
                }}
              >
              Create New Project
              </Button>
          </Box>

          <Box sx={{ 
            px: 3,
            pb: 3,
          }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 3 
            }}>
              <Box sx={{ display: 'inline-flex', gap: 1 }}>
                <Button 
                  variant={filter === 'all' ? 'contained' : 'text'} 
                  onClick={() => setFilter('all')}
                  sx={{ 
                    borderRadius: '30px', 
                    textTransform: 'none', 
                    px: 3,
                    py: 1,
                    bgcolor: filter === 'all' ? '#2A2C40' : '#F2F2F2',
                    color: filter === 'all' ? '#fff' : '#2A2C40',
                    '&:hover': {
                      bgcolor: filter === 'all' ? '#2A2C40' : '#E5E5E5',
                    },
                    fontWeight: 600,
                  }}
                >
                  All
                </Button>
                <Button 
                  variant={filter === 'in_progress' ? 'contained' : 'text'} 
                  onClick={() => setFilter('in_progress')}
                  sx={{ 
                    borderRadius: '30px', 
                    textTransform: 'none', 
                    px: 3,
                    py: 1,
                    bgcolor: filter === 'in_progress' ? '#2A2C40' : '#F2F2F2',
                    color: filter === 'in_progress' ? '#fff' : '#2A2C40',
                    '&:hover': {
                      bgcolor: filter === 'in_progress' ? '#2A2C40' : '#E5E5E5',
                    },
                    fontWeight: 600,
                  }}
                >
                  Active
                </Button>
                <Button 
                  variant={filter === 'completed' ? 'contained' : 'text'} 
                  onClick={() => setFilter('completed')}
                  sx={{ 
                    borderRadius: '30px', 
                    textTransform: 'none', 
                    px: 3,
                    py: 1,
                    bgcolor: filter === 'completed' ? '#2A2C40' : '#F2F2F2',
                    color: filter === 'completed' ? '#fff' : '#2A2C40',
                    '&:hover': {
                      bgcolor: filter === 'completed' ? '#2A2C40' : '#E5E5E5',
                    },
                    fontWeight: 600,
                  }}
                >
                  Completed
                </Button>
                <Button 
                  variant={filter === 'draft' ? 'contained' : 'text'} 
                  onClick={() => setFilter('draft')}
                  sx={{ 
                    borderRadius: '30px', 
                    textTransform: 'none', 
                    px: 3,
                    py: 1,
                    bgcolor: filter === 'draft' ? '#2A2C40' : '#F2F2F2',
                    color: filter === 'draft' ? '#fff' : '#2A2C40',
                    '&:hover': {
                      bgcolor: filter === 'draft' ? '#2A2C40' : '#E5E5E5',
                    },
                    fontWeight: 600,
                  }}
                >
                  Draft
                </Button>
              </Box>
              <TextField
                placeholder="Search projects..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  ),
                }}
                size="small"
                sx={{
                  width: '300px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    bgcolor: 'white',
                  }
                }}
              />
                    </Box>
        </Box>

        {error && (
            <Alert severity="error" sx={{ mx: 3, mb: 3 }}>
            {error}
          </Alert>
        )}

          {filteredProjects.length === 0 ? (
            <Box 
            sx={{ 
                p: 6, 
              textAlign: 'center',
                background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.paper, 1)} 100%)`,
            }}
          >
              <Typography variant="h6" color="text.secondary" gutterBottom sx={{ mb: 2 }}>
              No projects found
            </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                {filter === 'all' 
                  ? 'Start by creating your first project'
                  : `No ${filter.replace('_', ' ')} projects found`}
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate('/new-project')}
              sx={{
                  borderRadius: '30px',
                  textTransform: 'none',
                  px: 4,
                  py: 1.5,
                  fontSize: '1rem',
                  bgcolor: '#4169E1',
                }}
              >
                Create Project
            </Button>
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: 'text.secondary', fontWeight: 500 }}>Project Name</TableCell>
                  <TableCell sx={{ color: 'text.secondary', fontWeight: 500 }}>Function</TableCell>
                  <TableCell sx={{ color: 'text.secondary', fontWeight: 500 }}>Location</TableCell>
                  <TableCell sx={{ color: 'text.secondary', fontWeight: 500 }}>Status</TableCell>
                  <TableCell sx={{ color: 'text.secondary', fontWeight: 500 }}>Last Updated</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredProjects.map((project) => (
                  <StyledTableRow 
                    key={project.projectID}
                    onClick={() => navigate(`/project/${project.projectID}`)}
                  >
                    <TableCell>
                      <Typography fontWeight={600}>{project.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="text.secondary">{project.projectType}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="text.secondary">{project.address || 'N/A'}</Typography>
                    </TableCell>
                    <TableCell>
                      <StatusChip
                        label={project.status === 'in_progress' ? 'In Progress' : 
                               project.status === 'completed' ? 'Complete' : 'Pending'}
                        status={project.status}
                      >
                        {project.status === 'in_progress' ? 'In Progress' : 
                         project.status === 'completed' ? 'Complete' : 'Pending'}
                      </StatusChip>
                    </TableCell>
                    <TableCell>
                      <Typography color="text.secondary">
                        Today, 2:30 PM
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/project/${project.projectID}/edit`);
                        }}
                        sx={{
                          color: 'text.secondary',
                          '&:hover': {
                            color: 'primary.main',
                            bgcolor: alpha(theme.palette.primary.main, 0.1),
                          },
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          )}
          </Paper>
      </Container>
    </Box>
  );
};

export default Projects; 