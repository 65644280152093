import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Alert,
  useTheme,
  alpha,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Card,
  CardContent,
  CardHeader,
  Tab,
  Tabs,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Link,
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  GridView as GridViewIcon,
  List as ListViewIcon,
  Search as SearchIcon,
  InsertDriveFile as FileIcon,
  Image as ImageIcon,
  PictureAsPdf as PdfIcon,
  MoreVert as MoreVertIcon,
  Add as AddIcon,
  Description as DescriptionIcon,
  Download as DownloadIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Warning as WarningIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { Project, Permit } from '../types';
import { projectAPI, permitAPI } from '../apis';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';

// Constants from ProjectDetails
const PERMIT_TYPES = [
  'Building Permit',
  'Electrical Permit',
  'Plumbing Permit',
  'Mechanical Permit',
  'Demolition Permit',
  'Grading Permit',
  'Other'
];

interface FileItem {
  name: string;
  type: string;
  size: number;
  modifiedAt: Date;
  status?: string;
  version?: string;
  documentID?: string;
  permitID?: string;
  permitType?: string;
  projectID?: string;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
  },
}));

const FilterButton = styled(Button)(({ theme }) => ({
  borderRadius: '24px',
  padding: '8px 16px',
  textTransform: 'none',
  fontWeight: 500,
  color: theme.palette.text.primary,
  backgroundColor: '#F2F2F2', // Grey background for unselected filters
  '&.selected': {
    backgroundColor: '#2A2C40',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#2A2C40',
    },
  },
  '&:hover': {
    backgroundColor: alpha('#F2F2F2', 0.8),
  },
  margin: '0 2px',
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
}));

const Documents: React.FC = () => {
  const theme = useTheme();
  const [projects, setProjects] = useState<Project[]>([]);
  const [permits, setPermits] = useState<Permit[]>([]);
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [selectedPermit, setSelectedPermit] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('list');
  const [searchQuery, setSearchQuery] = useState('');
  const [files, setFiles] = useState<FileItem[]>([]);
  const [selectedFile, setSelectedFile] = useState<FileItem | null>(null);
  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);
  const [isCreatePermitDialogOpen, setIsCreatePermitDialogOpen] = useState(false);
  const [permitData, setPermitData] = useState({
    permitType: '',
    estimatedTimeline: '',
  });
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [selectedPermitType, setSelectedPermitType] = useState<string>('All');
  const [selectedCategory, setSelectedCategory] = useState('All');

  // Document categories
  const categories = ['All', 'Survey', 'Soil Report', 'Plumbing Plan', 'Electrical Plan', 'Site Plan', 'Others'];

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png']
    },
    onDrop: async (acceptedFiles) => {
      if (!selectedProject || !selectedPermit) {
        setError('Please select a project and permit first');
        return;
      }

      setLoading(true);
      setError(null);
      setSuccess(null);

      try {
        const formData = new FormData();
        acceptedFiles.forEach((file) => {
          formData.append('files', file);
        });

        const response = await permitAPI.createPermit(
          {
            permitID: selectedPermit,
            projectID: selectedProject,
          },
          acceptedFiles
        );

        if (response.status === 'success') {
          setSuccess('Documents uploaded successfully');
          // Add new files to the list
          const newFiles = acceptedFiles.map(file => ({
            name: file.name,
            type: file.type,
            size: file.size,
            modifiedAt: new Date(),
            status: 'pending',
            version: '1.0',
            documentID: Math.random().toString(36).substr(2, 9),
            permitID: selectedPermit,
          }));
          setFiles(prev => [...prev, ...newFiles]);
        } else {
          throw new Error(response.message || 'Failed to upload documents');
        }
      } catch (error) {
        console.error('Error uploading documents:', error);
        setError('Failed to upload documents. Please try again.');
      } finally {
        setLoading(false);
      }
    },
  });

  // Fetch all documents
  useEffect(() => {
    const fetchAllDocuments = async () => {
      setLoading(true);
      setError(null);
      try {
        // First get all permits
        const permitsResponse = await permitAPI.getPermits();
        if (permitsResponse.status === 'success' && permitsResponse.data) {
          const allDocuments: FileItem[] = [];
          
          // For each permit, get its documents
          for (const permit of permitsResponse.data) {
            const permitResponse = await permitAPI.getPermit(permit.permitID);
            if (permitResponse.status === 'success' && permitResponse.data?.documents) {
              const documents = permitResponse.data.documents.map(doc => ({
                name: doc.name,
                type: doc.type,
                size: doc.size,
                modifiedAt: new Date(doc.uploadDate),
                status: doc.status,
                version: doc.version?.toString(),
                documentID: doc.documentID,
                permitID: permit.permitID,
                permitType: permit.permitType,
                projectID: permit.projectID
              }));
              allDocuments.push(...documents);
            }
          }
          setFiles(allDocuments);
        }
      } catch (error) {
        console.error('Error fetching documents:', error);
        setError('Failed to fetch documents');
      } finally {
        setLoading(false);
      }
    };

    fetchAllDocuments();
  }, []);

  // Filter documents based on selected permit type and search query
  const filteredFiles = files.filter(file => {
    if (selectedPermitType !== 'All' && file.permitType !== selectedPermitType) return false;
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      return file.name.toLowerCase().includes(query) ||
             file.type.toLowerCase().includes(query) ||
             file.permitType?.toLowerCase().includes(query);
    }
    return true;
  });

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await projectAPI.getProjects();
        if (response.status === 'success' && response.data) {
          setProjects(response.data);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchPermits = async () => {
      if (!selectedProject) {
        setPermits([]);
        return;
      }

      try {
        const response = await permitAPI.getPermits();
        if (response.status === 'success' && response.data) {
          const projectPermits = response.data.filter(
            permit => permit.projectID === selectedProject
          );
          setPermits(projectPermits);
        }
      } catch (error) {
        console.error('Error fetching permits:', error);
      }
    };

    fetchPermits();
  }, [selectedProject]);

  // Helper function to get existing permit for a permit type
  const getExistingPermit = (permitType: string) => {
    return permits.find(permit => permit.permitType === permitType);
  };

  const getFileIcon = (fileType: string) => {
    if (fileType.includes('pdf')) return <PdfIcon color="error" />;
    if (fileType.includes('image')) return <ImageIcon color="primary" />;
    return <FileIcon color="action" />;
  };

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const getCategoryChip = (category: string) => {
    return (
      <Chip
        label={category}
        sx={{
          backgroundColor: '#EEEEEE',
          borderRadius: '16px',
          color: '#555555',
          fontWeight: 500,
        }}
        size="small"
      />
    );
  };

  const getStatusChip = (status: string) => {
    let color: 'success' | 'error' | 'warning' | 'default' = 'default';
    let icon: React.ReactNode = null;

    switch (status.toLowerCase()) {
      case 'approved':
        color = 'success';
        icon = <CheckCircleIcon />;
        break;
      case 'rejected':
        color = 'error';
        icon = <CancelIcon />;
        break;
      case 'pending':
        color = 'warning';
        icon = <WarningIcon />;
        break;
    }

    return (
      <Chip
        icon={icon || undefined}
        label={status.charAt(0).toUpperCase() + status.slice(1)}
        color={color}
        size="small"
      />
    );
  };

  const handleFileClick = (file: FileItem) => {
    setSelectedFile(file);
    setIsFileDialogOpen(true);
  };

  const handleCreatePermit = () => {
    setIsCreatePermitDialogOpen(true);
  };

  const handlePermitSubmit = async () => {
    if (!selectedProject) return;

    try {
      setError(null);
      const response = await permitAPI.createPermit({
        ...permitData,
        projectID: selectedProject,
      }, []);

      if (response.status === 'success' && response.data) {
        setPermits(prev => [...prev, response.data]);
        setIsCreatePermitDialogOpen(false);
        setPermitData({ permitType: '', estimatedTimeline: '' });
      } else {
        throw new Error(response.message || 'Failed to create permit');
      }
    } catch (error) {
      console.error('Error creating permit:', error);
      setError('Failed to create permit. Please try again later.');
    }
  };

  return (
    <Box sx={{ 
      pt: '80px',
      minHeight: '100vh', 
      bgcolor: '#F8F9FA',
      pb: 4
    }}>
      <Container maxWidth="lg">
        <Paper 
          sx={{ 
            borderRadius: '16px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
            overflow: 'hidden',
            mb: 4
          }}
        >
          <Box sx={{ 
            p: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <Typography variant="h5" fontWeight="600">Documents</Typography>
            <Button
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{
                borderRadius: '30px',
                textTransform: 'none',
                px: 3,
                py: 1.2,
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                bgcolor: '#4169E1',
                '&:hover': {
                  bgcolor: '#3457cd',
                },
                transition: 'all 0.2s ease',
                fontWeight: 600,
              }}
              onClick={() => setIsUploadDialogOpen(true)}
            >
              Upload New Files
            </Button>
          </Box>

          <Box sx={{ 
            px: 3,
            pb: 3,
          }}>
            {/* Filter Buttons */}
            <Box sx={{ display: 'flex', gap: 0.25, flexWrap: 'wrap' }}>
              <FilterButton
                key="All"
                onClick={() => setSelectedPermitType('All')}
                className={selectedPermitType === 'All' ? 'selected' : ''}
                disableElevation
              >
                All
              </FilterButton>
              {PERMIT_TYPES.map((type) => (
                <FilterButton
                  key={type}
                  onClick={() => setSelectedPermitType(type)}
                  className={selectedPermitType === type ? 'selected' : ''}
                  disableElevation
                >
                  {type}
                </FilterButton>
              ))}
            </Box>
          </Box>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
              <CircularProgress />
            </Box>
          ) : filteredFiles.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: 'text.secondary', fontWeight: 500 }}>Document Name</TableCell>
                  <TableCell sx={{ color: 'text.secondary', fontWeight: 500 }}>Project</TableCell>
                  <TableCell sx={{ color: 'text.secondary', fontWeight: 500 }}>File Category</TableCell>
                  <TableCell sx={{ color: 'text.secondary', fontWeight: 500 }}>Upload Date</TableCell>
                  <TableCell sx={{ color: 'text.secondary', fontWeight: 500 }}>Last Updated</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredFiles.map((file) => (
                  <StyledTableRow key={file.documentID || file.name}>
                    <TableCell>
                      <Typography fontWeight={600}>{file.name}</Typography>
                    </TableCell>
                    <TableCell>
                      {projects.find(p => p.projectID === file.projectID)?.name || "Downtown Highrise"}
                    </TableCell>
                    <TableCell>
                      {getCategoryChip(file.permitType || 'Others')}
                    </TableCell>
                    <TableCell>
                      {file.modifiedAt.toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })}
                    </TableCell>
                    <TableCell>
                      {file.modifiedAt.toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })}
                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ActionButton>
                          <EditIcon fontSize="small" />
                        </ActionButton>
                        <ActionButton>
                          <DownloadIcon fontSize="small" />
                        </ActionButton>
                        <ActionButton sx={{ color: 'error.main' }}>
                          <DeleteIcon fontSize="small" />
                        </ActionButton>
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                No documents found
              </Typography>
              <Button
                variant="contained"
                startIcon={<CloudUploadIcon />}
                sx={{
                  mt: 2,
                  borderRadius: '30px',
                  textTransform: 'none',
                  bgcolor: '#4169E1',
                }}
                onClick={() => setIsUploadDialogOpen(true)}
              >
                Upload Documents
              </Button>
            </Box>
          )}
        </Paper>

        {/* Upload Dialog */}
        <Dialog
          open={isUploadDialogOpen}
          onClose={() => setIsUploadDialogOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              Upload Documents
              <IconButton onClick={() => setIsUploadDialogOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            {/* Project and Permit Selection */}
            <Paper sx={{ p: 3, mb: 3, borderRadius: '16px' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Select Project</InputLabel>
                    <Select
                      value={selectedProject}
                      label="Select Project"
                      onChange={(e) => {
                        setSelectedProject(e.target.value);
                        setSelectedPermit('');
                      }}
                    >
                      {projects.map((project) => (
                        <MenuItem key={project.projectID} value={project.projectID}>
                          <Box>
                            <Typography variant="subtitle1">{project.name}</Typography>
                            <Typography variant="caption" color="text.secondary">
                              {project.projectType} • {project.address}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Select Permit</InputLabel>
                    <Select
                      value={selectedPermit}
                      label="Select Permit"
                      onChange={(e) => setSelectedPermit(e.target.value)}
                      disabled={!selectedProject}
                    >
                      {PERMIT_TYPES.map((type) => {
                        const existingPermit = getExistingPermit(type);
                        return (
                          <MenuItem 
                            key={existingPermit?.permitID || type} 
                            value={existingPermit?.permitID || type}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                          >
                            <Box>
                              <Typography variant="subtitle1">
                                {type}
                              </Typography>
                              {existingPermit ? (
                                <Typography variant="caption" color="text.secondary">
                                  Status: {existingPermit.status} • Created: {format(new Date(existingPermit.submissionDate), 'MMM d, yyyy')}
                                </Typography>
                              ) : (
                                <Typography variant="caption" color="text.secondary">
                                  No permit created yet
                                </Typography>
                              )}
                            </Box>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>

            {/* File Upload Area */}
            <Paper sx={{ mb: 3, borderRadius: '16px' }}>
              <Box
                {...(selectedProject && selectedPermit ? getRootProps() : {})}
                sx={{
                  border: '2px dashed',
                  borderColor: selectedProject && selectedPermit ? 'divider' : alpha(theme.palette.action.disabled, 0.5),
                  borderRadius: 1,
                  p: 3,
                  textAlign: 'center',
                  cursor: selectedProject && selectedPermit ? 'pointer' : 'not-allowed',
                  transition: 'all 0.2s ease-in-out',
                  bgcolor: selectedProject && selectedPermit ? 'transparent' : alpha(theme.palette.action.disabled, 0.04),
                  '&:hover': selectedProject && selectedPermit ? {
                    borderColor: 'primary.main',
                    bgcolor: alpha(theme.palette.primary.main, 0.04),
                    transform: 'scale(1.01)',
                  } : {},
                }}
              >
                {selectedProject && selectedPermit ? (
                  <>
                    <input {...getInputProps()} />
                    {loading ? (
                      <CircularProgress size={40} />
                    ) : (
                      <>
                        <CloudUploadIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 1 }} />
                        <Typography variant="h6" gutterBottom>
                          Drag and drop files here, or click to select files
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Supported formats: PDF, DOC, DOCX, JPG, PNG
                        </Typography>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <CloudUploadIcon sx={{ fontSize: 48, color: 'text.disabled', mb: 1 }} />
                    <Typography variant="h6" gutterBottom color="text.disabled">
                      Please select a project and permit first
                    </Typography>
                    <Typography variant="body2" color="text.disabled">
                      The upload area will be enabled once you make your selection
                    </Typography>
                  </>
                )}
              </Box>
            </Paper>

            {/* File List */}
            <Paper sx={{ p: 2 }}>
              {viewMode === 'list' ? (
                <List>
                  {files.map((file, index) => (
                    <React.Fragment key={file.name + index}>
                      <ListItem
                        sx={{
                          borderRadius: 1,
                          transition: 'all 0.2s ease-in-out',
                          cursor: 'pointer',
                          '&:hover': {
                            bgcolor: alpha(theme.palette.primary.main, 0.04),
                            transform: 'translateX(4px)',
                          },
                        }}
                      >
                        <ListItemIcon>
                          {getFileIcon(file.type)}
                        </ListItemIcon>
                        <ListItemText
                          primary={file.name}
                          secondary={
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                              <Typography variant="caption">
                                {formatFileSize(file.size)}
                              </Typography>
                              <Typography variant="caption">
                                Modified {format(file.modifiedAt, 'MMM d, yyyy')}
                              </Typography>
                              {file.version && (
                                <Typography variant="caption">
                                  Version {file.version}
                                </Typography>
                              )}
                            </Box>
                          }
                          onClick={() => handleFileClick(file)}
                        />
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          {file.status && getStatusChip(file.status)}
                          <IconButton edge="end" size="small">
                            <MoreVertIcon />
                          </IconButton>
                        </Box>
                      </ListItem>
                      {index < files.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                  {files.length === 0 && (
                    <ListItem>
                      <ListItemText
                        primary="No files uploaded yet"
                        secondary="Upload files by dragging them into the upload area above"
                      />
                    </ListItem>
                  )}
                </List>
              ) : (
                <Grid container spacing={2}>
                  {files.map((file, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={file.name + index}>
                      <Card
                        sx={{
                          height: '100%',
                          transition: 'all 0.2s ease-in-out',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: 4,
                          },
                          cursor: 'pointer',
                        }}
                        onClick={() => handleFileClick(file)}
                      >
                        <CardContent sx={{ p: 2 }}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                            {getFileIcon(file.type)}
                            <Typography noWrap sx={{ width: '100%', textAlign: 'center', fontWeight: 500 }}>
                              {file.name}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              {formatFileSize(file.size)}
                            </Typography>
                            {file.status && (
                              <Box sx={{ mt: 1 }}>
                                {getStatusChip(file.status)}
                              </Box>
                            )}
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                  {files.length === 0 && (
                    <Grid item xs={12}>
                      <Box sx={{ textAlign: 'center', py: 4 }}>
                        <DescriptionIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
                        <Typography color="text.secondary">
                          No files uploaded yet
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
            </Paper>
          </DialogContent>
        </Dialog>

        {/* File Details Dialog */}
        <Dialog
          open={isFileDialogOpen}
          onClose={() => setIsFileDialogOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              Document Details
              <IconButton onClick={() => setIsFileDialogOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            {selectedFile && (
              <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Document Name
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {selectedFile.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Type
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {selectedFile.type}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Size
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {formatFileSize(selectedFile.size)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last Modified
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {format(selectedFile.modifiedAt, 'MMM d, yyyy')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Version
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {selectedFile.version || 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Status
                    </Typography>
                    {selectedFile.status && (
                      <Chip
                        label={selectedFile.status}
                        color={selectedFile.status === 'approved' ? 'success' : 'default'}
                        size="small"
                        sx={{ mt: 1 }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsFileDialogOpen(false)}>Close</Button>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={() => {/* Handle download */}}
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>

        {/* Create Permit Dialog */}
        <Dialog
          open={isCreatePermitDialogOpen}
          onClose={() => setIsCreatePermitDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Create New Permit</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
              Please select the permit type and estimated timeline for completion.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Permit Type</InputLabel>
                  <Select
                    value={permitData.permitType}
                    label="Permit Type"
                    onChange={(e) => setPermitData({ ...permitData, permitType: e.target.value })}
                  >
                    {PERMIT_TYPES.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Estimated Timeline (weeks)"
                  type="number"
                  value={permitData.estimatedTimeline}
                  onChange={(e) => setPermitData({ ...permitData, estimatedTimeline: e.target.value })}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsCreatePermitDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handlePermitSubmit}
              disabled={!permitData.permitType || !permitData.estimatedTimeline}
            >
              Create Permit
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default Documents; 